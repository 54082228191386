window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');
import "controllers"

$(window).on('load', () => {

  let body_data = $('body').data();

  $(window).trigger(body_data.controller + ':loaded')
  $(window).trigger(body_data.controller + '#' + body_data.action + ':loaded')

  $(function () {

    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

    function handleSidebarLink(id){
      let active_sidebar_class = "active-sidebar"
      let inactive_sidebar_class = "text-white"
      $(id).addClass(active_sidebar_class).removeClass(inactive_sidebar_class);
    }

    // Menu
    let controller = body_data.controller;
    if ($.inArray(controller, ["companies", "users", "contracts", "scheduled_reports"]) >= 0)
      handleSidebarLink("#menu_companies_users")
    else if ($.inArray(controller, ["devices", "configurations", "models"]) >= 0)
      handleSidebarLink("#menu_devices_configurations")
    else if ($.inArray(controller, ["owners", "owner_groups", "availables_owner_groups"]) >= 0)
      handleSidebarLink("#menu_owner")
    else if ($.inArray(controller, ["availables", "exclusions"]) >= 0)
      handleSidebarLink("#menu_available")
    else if ($.inArray(controller, ["wifi_maps", "beacon_maps"]) >= 0)
      handleSidebarLink("#menu_maps")
    else if ($.inArray(controller, ["events"]) >= 0)
      handleSidebarLink("#menu_events")
    else if ($.inArray(controller, ["statistics"]) >= 0)
      handleSidebarLink("#menu_statistics")
    else
      handleSidebarLink("#menu_home")

    //Infinite Scroll
    var isscrolling = false;
    $(window).scroll(function () {

      // End of the document reached?
      if ($(document).height() - $(window).height() - 100 <= $(window).scrollTop() && $("[data-scroll]").length !== 0 && isscrolling === false) {
        $('#loading').css('display', 'flex');
        isscrolling = true;
        $.get($("[data-scroll]").data("scroll"), {range: $("[data-scroll] tbody tr").length}, function (data) {
          $("[data-scroll] tbody:first").append(data);
          isscrolling = (data === "");
          $('#loading').hide();
        });
      }
    });


    if (("Notification" in window) && (Notification.permission !== "granted")) {
      Notification.requestPermission().then(res => {})
    }

    //Datepicker (JQuery-ui)
    $.datepicker.setDefaults($.datepicker.regional['it']);
    $(function () {
      $("[type='datepicker']").datepicker();
    });

    //Hover su google-play-badge
    $("#google-play-badge").hover(function () {
        $(this).animate({
          opacity: 0.5
        }, 200);
      },
      function () {
        $(this).animate({
          opacity: 1.0
        }, 200);
      });

  });

});
